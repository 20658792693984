import React from "react";
import FaqQua from "../component/FaqQua";
import { Container, Breadcrumb, Accordion } from "react-bootstrap";

function Faq() {
    return (
        <>

            {/* FAQ Page  */}

            <section className="innerPage-heroSection">
                <Container>
                    <div className="innerPage-heroSectionInfo">
                        <h1>Our Faqs</h1>
                        <Breadcrumb className="innerPage-breadkrumb">
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>FAQs</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </Container>
            </section>

            <section className="main-faqsection">
                <Container>
                    <h5 className="faq-heading">How safeXvault App will help me?</h5>
                    <div className="faq-accordian">
                        <Accordion defaultActiveKey="0">
                            {
                                FaqQua.map(obj => {
                                    return (
                                        <>
                                            <Accordion.Item eventKey={obj.eventkey}>
                                                <Accordion.Header>
                                                    {obj.faqheading}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {obj.faqContant}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </>
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                </Container>
            </section>

            {/* FAQ Page  */}
        </>
    )
};

export default Faq;

