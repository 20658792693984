
import { Container, Breadcrumb } from "react-bootstrap";
import PrivacyPolicyInfo from "../component/PrivacyPolicyInfo";

function PrivacyPolicy() {
    return (
        <>
            <section className="innerPage-heroSection">
                <Container>
                    <div className="innerPage-heroSectionInfo">
                        <h1> privacy policy</h1>
                        <Breadcrumb className="innerPage-breadkrumb">
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active> privacy policy</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </Container>
            </section>

            <section className="main-privacyPolicy">
                <Container>
                    <div className="main-privacyPolicyInfo">
                        {
                            PrivacyPolicyInfo.map(
                                obj => {
                                    return (
                                        <>
                                            <h4>{obj.privacyHeading}</h4>
                                            <p>{obj.privacyContant}
                                                <span>
                                                    {obj.privacyContantSpan}
                                                </span>
                                            </p>
                                        </>
                                    )
                                }
                            )
                        }
                    </div>
                </Container>
            </section>
        </>
    )
};

export default PrivacyPolicy;