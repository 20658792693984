
import premiumVersion from "../assets/img/PREMIUM VERSION/PREMIUM VERSION INFO 1.png";
import premiumVersion2 from "../assets/img/PREMIUM VERSION/PREMIUM VERSION INFO 2.png";
import premiumVersion3 from "../assets/img/PREMIUM VERSION/PREMIUM VERSION INFO 3.png";
import premiumVersion4 from "../assets/img/PREMIUM VERSION/PREMIUM VERSION INFO 4.png";
import premiumVersion5 from "../assets/img/PREMIUM VERSION/PREMIUM VERSION INFO 5.png";

const PremiumVersionInfo = [
    {
        key: 1,
        PremiumVersionInfoImg: { premiumVersion },
        PremiumVersionInfoHeadingSpan: "",
        PremiumVersionInfoHeading: "How do you use my personal information?",
        PremiumVersionInfoContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 2,
        PremiumVersionInfoImg: { premiumVersion2 },
        PremiumVersionInfoHeadingSpan: "Add Unlimited ",
        PremiumVersionInfoHeading: "Reminders for",
        PremiumVersionInfoContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 3,
        PremiumVersionInfoImg: { premiumVersion3 },
        PremiumVersionInfoHeadingSpan: "Backup ",
        PremiumVersionInfoHeading: "& Restore",
        PremiumVersionInfoContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 4,
        PremiumVersionInfoImg: { premiumVersion4 },
        PremiumVersionInfoHeadingSpan: "No ",
        PremiumVersionInfoHeading: "Ads",
        PremiumVersionInfoContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 5,
        PremiumVersionInfoImg: { premiumVersion5 },
        PremiumVersionInfoHeadingSpan: "More Features ",
        PremiumVersionInfoHeading: "are coming soon",
        PremiumVersionInfoContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
]

export default PremiumVersionInfo;