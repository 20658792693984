import { Row, Col, Card } from "react-bootstrap";
import benefitsCardImg1 from "../assets/img/home/cardLogo1.png";
import benefitsCardImg2 from "../assets/img/home/cardLogo2.png";
import benefitsCardImg3 from "../assets/img/home/cardLogo3.png";
import benefitsCardImg4 from "../assets/img/home/cardLogo4.png";

const benefitsCardInfo = [
    {
        key: 1,
        benefitsCardImg: benefitsCardImg1,
        benefitsCardTitle: "FREE ACCESS",
        benefitsCardContant: "Lorem Ipsum is simply dummy text of the industry. Lorem Ipsum has!",
        // benefitsCardBtn: "READ MORE",
    },
    {
        key: 2,
        benefitsCardImg: benefitsCardImg2,
        benefitsCardTitle: "24/7 SUPPORT",
        benefitsCardContant: "Lorem Ipsum is simply dummy text of the industry. Lorem Ipsum has!",
        // benefitsCardBtn: "READ MORE",
    },
    {
        key: 3,
        benefitsCardImg: benefitsCardImg3,
        benefitsCardTitle: "SECURE DATA",
        benefitsCardContant: "Lorem Ipsum is simply dummy text of the industry. Lorem Ipsum has!",
        // benefitsCardBtn: "READ MORE",
    },
    {
        key: 4,
        benefitsCardImg: benefitsCardImg4,
        benefitsCardTitle: "SEND MESSAGES",
        benefitsCardContant: "Lorem Ipsum is simply dummy text of the industry. Lorem Ipsum has!",
        // benefitsCardBtn: "READ MORE",
    },
]

const BenefitsCard = () => {
    return (
        <>
            <Row>
                {benefitsCardInfo.map(
                    obj => {
                        return (
                            <>
                                <Col xl={3} sm={6} className="mb-3">
                                    <Card className="benefitsCard h-100">
                                        <Card.Img variant="top" className="benefitsCardImg img-fluid" src={obj.benefitsCardImg} />
                                        <Card.Body className="benefitsCard-body">
                                            <Card.Title className="benefitsCard-title">{obj.benefitsCardTitle}</Card.Title>
                                            <Card.Text className="benefitsCard-contant">
                                                {obj.benefitsCardContant}
                                            </Card.Text>
                                        </Card.Body>
                                        {/* <Card.Footer className="benefitsCard-footer">
                                            <button className="benefitsCard-btn">{obj.benefitsCardBtn}</button>
                                        </Card.Footer> */}
                                    </Card>
                                </Col>
                            </>
                        )
                    }
                )}
            </Row>
        </>
    )
}

export default BenefitsCard;



