// import react from "react";
import { useState, useEffect } from 'react';
import { Navbar, Container, Nav } from "react-bootstrap";
import logo from "../assets/img/logo/logo.png";

function Header() {
    // for burger menu 
    const [isBurgerMenuActive, setIsActive] = useState(false);
    const handleClick = event => {
        setIsActive(current => !current);
    };
    // for burger menu 

    // for scrolling navbar add class 
    const [scroll, setScroll] = useState(false)
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 100)
        })
    }, [])
    // for scrolling navbar add class 

    return (
        <>
            <header className={scroll ? "header headerScrolled" : "header"}>
                <Container className="padding-0">
                    <Navbar className="main-navbar" expand="lg">
                        <Navbar.Brand className="logoDiv" href="/">
                            <img src={logo} alt="logo" />
                            <span className="LogoName">safe<span className="logoBoldX">X</span>vault</span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" className={isBurgerMenuActive ? 'burger-menu burger-menu2' : 'burger-menu'} onClick={handleClick} />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto navbar-listingDiv">
                                <li className="navbar-listing">
                                    <Nav.Link className="navbar-link active-page" href="/">Home</Nav.Link>
                                </li>
                                <li className="navbar-listing">
                                    <Nav.Link className="navbar-link" href="/upgrade">Premium Version</Nav.Link>
                                </li>
                                <li className="navbar-listing">
                                    <Nav.Link className="navbar-link" href="/privacy-policy">privacy policy</Nav.Link>
                                </li>
                                <li className="navbar-listing">
                                    <Nav.Link className="navbar-link" href="/faqs">FAQs</Nav.Link>
                                </li>
                                <li className="navbar-listing">
                                    <Nav.Link className="navbar-link" href="/support">Support</Nav.Link>
                                </li>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </header>
        </>
    )
}

export default Header;
