const FaqQua = [
    {
        key: 1,
        eventkey: "0",
        faqheading: "Do I need to provide my any personal information to use this app?",
        faqContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 2,
        eventkey: "1",
        faqheading: "Which platform does safeXvault support?",
        faqContant: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
    },
    {
        key: 3,
        eventkey: "2",
        faqheading: "Is safeXvault app storing my reminders online?",
        faqContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 4,
        eventkey: "3",
        faqheading: "What happens about my all scheduled reminder if I will change/loss my device?",
        faqContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 5,
        eventkey: "4",
        faqheading: "Can I set recurring reminders using safeXvault?",
        faqContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 6,
        eventkey: "5",
        faqheading: "Do I need to purchase this premium feature every time whenever I change my device?",
        faqContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 7,
        eventkey: "6",
        faqheading: "How should I post my questions?",
        faqContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 8,
        eventkey: "7",
        faqheading: "What is the use of currency in setting screen?",
        faqContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 9,
        eventkey: "8",
        faqheading: "Can I send birthday wish message from app?",
        faqContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        key: 10,
        eventkey: "9",
        faqheading: "Can I snooze my reminders?",
        faqContant: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
]

export default FaqQua;