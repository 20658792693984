import { Breadcrumb, Button, Col, Container, Form, Row } from "react-bootstrap";

import callImg from "../assets/img/call.png";
import mailImg from "../assets/img/mail.png";
import locationImg from "../assets/img/location.png";

function Support() {
    return (
        <>
            <section className="innerPage-heroSection">
                <Container>
                    <div className="innerPage-heroSectionInfo">
                        <h1> Support</h1>
                        <Breadcrumb className="innerPage-breadkrumb">
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active> Support</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </Container>
            </section>

            <section className="support-formsection">
                <Container>
                    <div className="support-formDiscription">
                        <h5>Get In Touch</h5>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt uvt labore et dolore magna aliqua.
                            sed do eiusmod tempor incididunt ut labore et dolore magna.
                        </p>
                    </div>
                    <div className="support-formDiv">
                        <Form className="support-form">
                            <Row>
                                <Col md="4">
                                    <Form.Group className="supportForm-inputDiv" controlId="formBasicName">
                                        <Form.Control type="text" placeholder="Your Name" />
                                    </Form.Group>
                                </Col>
                                <Col md="4">
                                    <Form.Group className="supportForm-inputDiv" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Your Email" />
                                    </Form.Group>
                                </Col>
                                <Col md="4">
                                    <Form.Group className="supportForm-inputDiv" controlId="formBasicsubject">
                                        <Form.Control type="text" placeholder="Subject" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="supportForm-inputDiv" controlId="formBasicmessage">
                                <Form.Control as="textarea" placeholder="Message" rows={8} />
                            </Form.Group>
                            <Button className="submitBtn" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </div>
                </Container>
            </section>

            <section className="support-locationDiv">
                <Container>
                    <div className="support-locationinfoDiv">
                        <h2>Nexuslink Services</h2>
                        <p>
                            Address: 441-442, Maruti Plaza, Fourth Floor, Nr. Vijay Park BRTS, Highway No.8, Krishna Nagar,
                            Naroda, Ahmedabad-382350, Gujarat, India
                        </p>
                    </div>
                    <Row className="support-otherInfoDiv">
                        <Col lg="4">
                            <div className="mb-3 support-otherInfo">
                                <div className="support-otherImg">
                                    <img src={callImg} className="img-fluid" alt="" />
                                </div>
                                <a href="#">
                                    <span>+91 (0)79 65257575</span>
                                </a>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="mb-3 support-otherInfo">
                                <div className="support-otherImg">
                                    <img src={mailImg} className="img-fluid" alt="" />
                                </div>
                                <a href="#">
                                    <span>info@nexuslinkservices.com</span>
                                </a>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="mb-3 support-otherInfo">
                                <div className="support-otherImg">
                                    <img src={locationImg} className="img-fluid" alt="" />
                                </div>
                                <a href="#">
                                    <span>www.nexuslinkservices.com</span>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Support;