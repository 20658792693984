import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./core/style.css";

// slider Link
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';
import '@splidejs/react-splide/css/core';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import PrivacyPolicy from './views/PrivacyPolicy';
import Support from './views/Support';
import Faq from './views/Faq';
import PremiumVersion from './views/PremiumVersion';
import HomePage from './views/HomePage';
// slider Link

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<HomePage />} />
          <Route path="support" element={<Support />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="faqs" element={<Faq />} />
          <Route path="upgrade" element={<PremiumVersion />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
